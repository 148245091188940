const Header = () => {
  return (
    <div className='container'>
      <button className='downlad-btn get-cv'>Get CV</button>
      <button className='downlad-btn hire-now'>Hire now</button>
    </div>
  )
}

export default Header
